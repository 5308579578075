import {
  convertKeysToSnakeCase,
  saveEncodedDataToLocalStorage,
} from '@/services/utils/localStorageUtils'
import { ATTRIBUTION_COOKIE_NAME } from 'lib/@getethos/analytics/constants'
import { getAttributionCookie } from 'lib/@getethos/analytics/getAttributionCookie'
import { setAndGetUserUuid } from 'lib/FeaturesAndExperiments/setAndGetUserUUID'
import { set } from 'lodash'
import { isNonAgentPartner } from 'src/shared/partners/nonAgentPartners'
import { v4 as uuidv4 } from 'uuid'

enum DeviceType {
  PHONE = 'phone',
  TABLET_AND_UP = 'tabletAndUp',
}

interface LandingPageAttributes {
  utmSource: string | null
  utmCampaign: string | null
  utmMedium: string | null
  utmTerm: string | null
  utmContent: string | null
  referrer: string
  deviceType: DeviceType
  eCampaignId: string | null
  eAdId?: string | null
  p?: string | null
}
const landingPageAttributes: LandingPageAttributes = {
  utmSource: null,
  utmCampaign: null,
  utmMedium: null,
  utmTerm: null,
  utmContent: null,
  referrer: '',
  deviceType: DeviceType.PHONE, // defaults to phone if window.screen is somehow not available
  eCampaignId: null,
  p: null,
}

const urlAttributes: Record<string, string> = {
  utmSource: 'utm_source',
  utmCampaign: 'utm_campaign',
  utmMedium: 'utm_medium',
  utmTerm: 'utm_term',
  utmContent: 'utm_content',
  eCampaignId: 'e_campaign_id',
  eAdId: 'e_ad_id',
  p: 'p',
}

let userId = uuidv4()

const paramMapping: Record<string, string[]> = {
  ref: ['ref', 'utm_source'],
  campaignId: ['campaignId'],
  contentId: ['contentId'],
  utmSource: ['utm_source'],
  utmMedium: ['utm_medium'],
  utmCampaign: ['utm_campaign'],
  utmTerm: ['utm_term'],
  utmContent: ['utm_content'],
  trackingId: ['trackingId'],
  fbclid: ['fbclid'],
  gclid: ['gclid'],
  msclkid: ['msclkid'],
  ttclid: ['ttclid'],
  ScCid: ['ScCid', 'sccid'],
  martech_data: ['martech_data'],
  p: ['p'],
  quote_id: ['quoteId'],
  po: ['po'],
  productType: ['productType'],
  partnerCode: ['partnerCode'],
  ex_cid: ['ex_cid'],
  e_ad_id: ['e_ad_id'],
  e_adset_id: ['e_adset_id'],
  e_campaign_id: ['e_campaign_id'],
  referrer: ['referrer'],
  external: ['external_id'],
}

const parseQueryParams = (query: string) => {
  const searchParams = new URLSearchParams(query)
  const result: Record<string, string | null> = {}

  for (const [key, keysToCheck] of Object.entries(paramMapping)) {
    for (const k of keysToCheck) {
      const value = searchParams.get(k)
      if (value) {
        result[key] = value
        break // Stop at the first matching value
      }
    }
  }

  const snack_param = convertKeysToSnakeCase(paramMapping)
  // Add all parameters not in paramMapping
  for (const [key, value] of searchParams.entries()) {
    if (!Object.values(snack_param).flat().includes(key)) {
      result[key] = value
    }
  }

  // Handle special cases
  result.referrer = document.referrer || result.referrer || ' '
  if (searchParams.get('external_id')) {
    result.external = JSON.stringify({
      external_id: searchParams.get('external_id'),
    })
  }

  // Clean up null, undefined, or empty string values
  return Object.fromEntries(
    Object.entries(result).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ''
    )
  )
}

const handleQueryParamsAndSave = () => {
  const query = window.location.search.slice(1)
  const queryParams = parseQueryParams(query)
  saveEncodedDataToLocalStorage(ATTRIBUTION_COOKIE_NAME, queryParams)
}

export const useLandingPageAttributes = () => {
  if (typeof window !== 'undefined') {
    userId = setAndGetUserUuid()

    // check window search for attribute parameters
    const trackCookie = getAttributionCookie() as Record<
      keyof typeof urlAttributes,
      any
    >

    const queryParams = new URLSearchParams(window.location.search.slice(1))

    handleQueryParamsAndSave()

    Object.keys(urlAttributes).forEach((key) => {
      const param = queryParams.get(
        urlAttributes[key as keyof typeof urlAttributes]
      )
      const cookieValue = trackCookie[key]
      if (param) {
        // set partner code as optimizely attribute if non agent
        if (key === 'p') {
          const partnerCode = param
          const isNonAgent = isNonAgentPartner({ partnerCode })
          const partnerCodeAttribute = isNonAgent ? null : partnerCode
          set(landingPageAttributes, key, partnerCodeAttribute)
        } else {
          set(landingPageAttributes, key, param)
        }
      } else if (cookieValue) {
        set(landingPageAttributes, key, cookieValue)
      }
    })
    if (document.referrer) {
      landingPageAttributes.referrer = document.referrer
    } else if (trackCookie.referrer) {
      landingPageAttributes.referrer = trackCookie.referrer
    }

    // check screen width and set deviceType attribute
    if (window.screen && window.screen.width !== undefined) {
      const screenWidth = window.screen.width
      const deviceType =
        screenWidth >= 600 ? DeviceType.TABLET_AND_UP : DeviceType.PHONE
      set(landingPageAttributes, 'deviceType', deviceType)
    }
  }

  return { landingPageAttributes, userId }
}
